import React from 'react'
import PropTypes from 'prop-types'

const Testimonials = ({testimonials}) => (
  <section className="hero is-feature-grey edge--top">
    <div className="hero-body">
      <div className="container">
        <p className="title">Testimonials</p>
        <div className={``}>
          {testimonials.map((testimonial, id) => (
            <article className='message' key={id}>
              <div className='message-body'>
                {testimonial.quote}
                <br />
                <cite> – {testimonial.author}</cite>
              </div>
            </article>
          ))}
        </div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </div>
  </section>
)

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string,
    })
  ),
}

export default Testimonials
