import React from 'react'
import PropTypes from 'prop-types'
import { isNull } from 'lodash'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'
import { lazysizes } from 'lazysizes'


function isOdd(num) {
  return num % 2
}

const Offerings = ({ gridItems }) => (
  <div id='offerings'>
    {gridItems.map((item, id) => (
      <section
        id={`offerings-${id}`}
        className={`hero is-medium ${
          isOdd(id) ? `edge--both--reverse is-feature-grey` : `edge--both is-light-primary`
        }`}
        key={id}
      >
        <div className='hero-body offerings'>
          <div className='container'>
            <div className={`hero-${id} columns`}>
              <div className='column '>
                {isOdd(id) ? (
                  <Fade top cascade>
                    <div className=''>
                      <img
                        alt='\'
                        className={`blurb-image lazyload`}
                        aria-label={`Image portraying ${item.blurb_heading}`}
                        data-src={item.image}
                      />
                    </div>
                  </Fade>
                ) : (
                  <Fade bottom cascade>
                    <div>
                      <h1 className='title'>{item.blurb_heading}</h1>
                      <div
                        className={`subtitle`}
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                      <Link
                        className={'button is-large is-primary is-outlined is-hidden'}
                        aria-label={`Keep Reading ${item.blurb_heading}`}
                        to={`${item.url}`}
                      >
                        Find out more →
                      </Link>
                      {isNull(item.badge) ? (
                        ''
                      ) : (
                        <img
                          className={`blurb-badge lazyload`}
                          alt='\'
                          aria-label={`Image portraying ${item.badge}`}
                          data-src={item.badge}
                        />
                      )}
                    </div>
                  </Fade>
                )}
              </div>
              <div className='column'>
                {isOdd(id) ? (
                  <Fade bottom cascade>
                    <div>
                      <h1 className='title'>{item.blurb_heading}</h1>
                      <div
                        className={`subtitle`}
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                      <Link
                        className={'button is-large is-primary is-outlined  is-hidden'}
                        aria-label={`Keep Reading ${item.blurb_heading}`}
                        to={`${item.url}`}
                      >
                        Find out more →
                      </Link>
                      {isNull(item.badge) ? (
                        ''
                      ) : (
                        <img
                          className={`blurb-badge lazyload`}
                          alt='\'
                          aria-label={`Image portraying ${item.badge}`}
                          data-src={item.badge}
                        />
                      )}
                    </div>
                  </Fade>
                ) : (
                  <Fade top cascade>
                    <div className=''>
                      <img
                        alt='\'
                        className={`blurb-image lazyload`}
                        aria-label={`Image portraying ${item.blurb_heading}`}
                        data-src={item.image}
                      />
                    </div>
                  </Fade>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    ))}
  </div>
)

Offerings.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      text: PropTypes.string,
      blurb_heading: PropTypes.string,
      url: PropTypes.string,
      badge: PropTypes.string,
    })
  )
}

export default Offerings
