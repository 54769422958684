import React from 'react'
import Helmet from 'react-helmet'
import Offerings from '../Offerings'
import Testimonials from '../Testimonials'
import PropTypes, { checkPropTypes } from 'prop-types'
import SE0 from '../SEO'
import GoogleMap from '../GoogleMap'
import HeroBanner from '../Hero'
import Fade from 'react-reveal/Fade'

const HomePageTemplate = ({
  title,
  cover,
  slug,
  subtitle,
  cta_title,
  cta_url,
  cta_video_link,
  heading,
  description,
  offerings,
  meta_title,
  meta_description,
  testimonials,
  google_map,
  hero_settings,
}) => (
  <div>
    <SE0
      title={title}
      meta_title={meta_title}
      meta_desc={meta_description}
      cover={cover}
      slug={slug}
    />
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>

    <HeroBanner
      cover={cover}
      title={title}
      subtitle={subtitle}
      hero_settings={hero_settings}
      cta_title={cta_title}
      cta_url={cta_url}
      cta_video_link={cta_video_link}
    />

    <section className='hero is-medium is-feature-grey edge--top--reverse'>
      <div className='hero-body'>
        <div className='container'>
          <Fade>
            <h1 className='title'>{heading}</h1>
            <div
              className='subtitle'
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Fade>
        </div>
      </div>
    </section>
    <Offerings gridItems={offerings.blurbs} />
    <Testimonials testimonials={testimonials} />
  </div>
)

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  cover: PropTypes.string,
  subtitle: PropTypes.string,
  cta_title: PropTypes.string,
  cta_url: PropTypes.string,
  cta_video_link: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array
  }),
  testimonials: PropTypes.array,
  google_map: PropTypes.shape({
    default_zoom: PropTypes.number,
    default_center: PropTypes.array,
    default_options: PropTypes.array,
    markers: PropTypes.array,
  }),
  hero_settings: PropTypes.shape()
}

export default HomePageTemplate
