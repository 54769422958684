import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import HomePageTemplate from '../components/HomePageTemplate'

const HomePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  // console.log(data)
  return (
    <HomePageTemplate
      title={frontmatter.title}
      cover={frontmatter.cover}
      slug={frontmatter.slug}
      subtitle={frontmatter.subtitle}
      cta_title={frontmatter.cta_title}
      cta_url={frontmatter.cta_url}
      cta_video_link={frontmatter.cta_video_link}
      meta_title={frontmatter.meta_title}
      meta_description={frontmatter.meta_description}
      heading={frontmatter.heading}
      description={frontmatter.description}
      offerings={frontmatter.offerings}
      testimonials={frontmatter.testimonials}
      google_map={frontmatter.google_map}
      hero_settings={frontmatter.hero_settings}
    />
  )
}

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default HomePage

export const pageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        cover
        slug
        subtitle
        cta_title
        cta_url
        cta_video_link
        hero_settings {
          size
          particle
          images
          css
          font_color
          title_align
        }
        meta_title
        meta_description
        heading
        description
        offerings {
          blurbs {
            image
            blurb_heading
            url
            text
            badge
          }
        }
        testimonials {
          author
          quote
        }
        google_map {
          default_zoom
          default_center {
            lat
            lng
          }
          default_options {
            styles
            disableDefaultUI
            zoomControl
            draggable
          }
          markers {
            lat
            lng
            text
          }
        }
      }
    }
  }
`
